import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import Header from "../modules/header";
import AlumniList from "../modules/alumni-list";

//Colour key values for header colour

export const query = graphql`
  query AlumniQuery($id: String!) {
    page: sanityPageAlumni(id: { eq: $id }) {
      title
      ...alumniBuilderFields
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      heroTitle
      heroText
      breadcrumbs {
        title
        slug
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    alumni: allSanityAlumnus(sort: { fields: name }) {
      edges {
        node {
          year
          state
          projectFocus
          postcode
          name
          city
        }
      }
    }
  }
`;

const Alumni = props => {
  const { data } = props;
  const page = data.page;
  const alumni = data.alumni.edges;

  //Seperate Alumni into states
  let sortedAlumni = [];
  if (alumni?.[0]) {
    let uniqueStates = [...new Set(alumni.map(item => item.node.state))].sort();
    uniqueStates.forEach((state, i) => {
      sortedAlumni.push({ title: state });
      sortedAlumni[i].alumni = [];
      alumni
        .filter(alumnus => alumnus.node.state === state)
        .forEach((alumnus, j) => {
          sortedAlumni[i].alumni.push(alumnus.node);
        });
    });
  }

  const withoutCTA = page.pageBuilder.filter(item => item._type !== "cta");
  const withCTA = page.pageBuilder.filter(item => item._type === "cta");

  return (
    <Layout className="alumni">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.heroTitle || page.title}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        text={page.heroText}
        backgroundColour={"beige"}
      />
      {page._rawBody?.[0] && (
        <div className="container m-body-content-wrapper">
          <BlockContent
            className="m-body-content"
            blocks={page._rawBody}
            serializers={{
              serializers,
              types: {
                block: serializers.types.block,
                undefined: serializers.undefined,
                blockVideo: serializers.blockVideo,
                blockFigure: serializers.blockFigure,
                pdfButton: serializers.pdfButton,
              },
              marks: {
                link: serializers.marks.link,
              },
            }}
            renderContainerOnSingleChild={true}
          />
        </div>
      )}

      <PageBuilder builder={withoutCTA} />

      {sortedAlumni?.[0] && (
        <div className="p-alumni-list">
          {sortedAlumni.map((state, i) => (
            <AlumniList title={state.title} alumni={state.alumni} key={i} />
          ))}
        </div>
      )}

      <PageBuilder builder={withCTA} />
    </Layout>
  );
};

Alumni.propTypes = {
  data: PropTypes.object,
};

export default Alumni;
