/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronDown } from "../helpers/icons";

const AlumniList = ({ title, alumni }) => {
  const [toggleList, setToggleList] = useState(false);
  const handleClick = e => {
    e.preventDefault();
    setToggleList(!toggleList);
  };

  let uniqueYears;
  if (alumni?.[0]) {
    uniqueYears = [...new Set(alumni.map(item => item.year))].sort();
  }
  return (
    alumni &&
    alumni[0] && (
      <div className={`m-alumni-list-container${toggleList ? " -active" : ""}`}>
        <div className="m-alumni-inner inner">
          <a
            href="#"
            onClick={handleClick}
            className={`m-alumni-toggle${toggleList ? " -active" : ""}`}
          >
            {title && <h2 className="m-alumni-title h4">{title}</h2>}
            <ChevronDown className="m-alumni-icon" />
          </a>
          <table
            className={`m-alumni-list-table${toggleList ? " -active" : ""}`}
          >
            <tbody className="m-alumni-table-body">
              <tr className="m-alumni-list-desktop-headers">
                <th className="metadata">Year</th>
                <th className="metadata">Name</th>
                <th className="metadata">City</th>
                <th className="metadata">P/C</th>
                <th className="metadata">Project Focus</th>
              </tr>
              {uniqueYears.map((year, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr className="m-alumni-list-mobile-headers">
                      <th colSpan="5" className="metadata">
                        {year}
                      </th>
                    </tr>
                    {alumni
                      .filter(alumnus => alumnus.year === year)
                      .map((alumnus, j, arr) => {
                        return (
                          <tr
                            className={`m-alumni-list-alumnus${
                              j === arr.length - 1 ? " -last" : ""
                            }`}
                            key={j}
                          >
                            <td className="m-alumni-list-year">
                              {alumnus.year || ""}
                            </td>
                            <td className="m-alumni-list-name">
                              {alumnus.name || ""}
                            </td>
                            <td className="m-alumni-list-city">
                              {alumnus.city || ""}
                            </td>
                            <td className="m-alumni-list-postcode">
                              {alumnus.postcode || ""}
                            </td>
                            <td className="m-alumni-list-project">
                              {alumnus.projectFocus || ""}
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

AlumniList.propTypes = {
  title: PropTypes.string,
  alumni: PropTypes.array,
};

export default AlumniList;
